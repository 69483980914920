<template>
  <v-row>
    <v-col>
    <hero-alt :title="currentComponentTitle"></hero-alt>
    <component :is="currentComponent" class="ArticleComponent"></component>
    <!-- <SectionArticle></SectionArticle> -->
    <NewsletterAlt></NewsletterAlt>
    <!-- <InfoAltVue></InfoAltVue> -->
    </v-col>
  </v-row>
</template>
<script>
// Extensions
// import View from "@/views/View";
// import article1 from "@/views/resources/newsArticles/article-1.vue";
// Mixins
// import LoadSections from "@/mixins/load-sections";

import HeroAlt from "@/views/sections/HeroAlt.vue";
// import SectionArticle from "@/views/sections/Article.vue";
import NewsletterAlt from "@/views/sections/NewsletterAlt.vue";
// import InfoAltVue from '@/views/sections/InfoAlt.vue';
import ArticleComponent from "@/views/resources/newsArticles/index.js";


export default {
    name: "Article",
    metaInfo: { title: "Single Article" },

    // extends: View,
    
    // mixins: [
    //      LoadSections([
    //         "hero-alt",
    //         "article",
    //         // `blog-${k}`,
    //         //'social-media',
    //         //'news',
    //         "newsletter-alt",
    //         "info-alt",
    //     ]),
    // ],
    props: {
        article: {
            type: Object,
            default: () => ({}),
        },
        id: {
            type: String,
            default: "article",
        },
    },
    data(){
      return {
        currentComponent:null,
        currentComponentTitle:"",
      }
    },
    mounted(){
      ArticleComponent.forEach(component => {
        if(this.$route.params.slug === component.url){
          this.currentComponent = component;
          this.currentComponentTitle = component.title;
        }
      });
    },
    components:{
      HeroAlt,
      // SectionArticle,
      NewsletterAlt,
      // InfoAltVue,
    }

};

</script>

<style scoped>
/* *{
  padding: 0;
} */
.ArticleComponent{
  width: 80%;
  margin: 2rem auto;
}
</style>
