<template>
<div>
    <base-info-card :title="title" :subtitle="subtitle" space="4" color="primary" />

    <form @submit.prevent="submitForm">
        <base-text-field label="Name" v-model="formData.name" />
        <base-text-field label="Email" v-model="formData.email" />
        <base-text-field label="Subject" v-model="formData.subject" />
        <base-textarea class="mb-6" label="Your Need & Description" v-model="formData.description" />

        <base-btn :color="!theme.isDark ? 'accent' : 'white'" type="submit" outlined>
            Send message
        </base-btn>
    </form>
    <div v-if="showConfirmation" class="custom-modal">

        <div class="modal-content">

            <h2>Your mail has been sent!</h2>

            <button @click="closeDialog">OK</button>

        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'BaseContactForm',
    inject: ['theme'],
    props: {
        subtitle: String,
        title: {
            type: String,
            default: 'MAIL US YOUR MESSAGE',
        },
    },
    data() {
        return {
            formData: {
                name: '',
                email: '',
                subject: '',
                description: '',
            },
            showConfirmation: false,
        };
    },
    methods: {
        async submitForm() {
            if (!this.validateFormData()) {
                // Display an error message to the user or handle validation as needed
                return;
            }

            // Create a FormData object to submit the data to the Google Form
            const formData = new FormData();
            formData.append('entry.1649658647', this.formData.name); // Replace with the actual entry IDs
            formData.append('entry.392828901', this.formData.email);
            formData.append('entry.823147614', this.formData.subject);
            formData.append('entry.365372785', this.formData.description);

            try {
                const response = await fetch(
                    'https://docs.google.com/forms/d/e/1FAIpQLSe7-qa39lDjrKZpLDS-JCWevFwcNZBXuWgD6m4NRGwuIvY-Aw/formResponse', {
                        method: 'POST',
                        body: formData,
                        mode: 'no-cors',
                    }
                );

                if (response.type === 'opaque') {
                    // Form submission was successful
                    // Optionally, show a confirmation message to the user
                    console.log('Form submitted successfully');
                    this.showConfirmation = true;
                } else {
                    // Form submission failed
                    // Optionally, handle the failure
                    console.error('Form submission failed');
                }
            } catch (error) {
                // Handle any errors that occur during the form submission
                console.error('An error occurred:', error);
            }
        },
        validateFormData() {
            // Add your validation logic here
            // Return true if the data is valid, false otherwise
            // Example validation: check if email is in a valid format
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(this.formData.email);
        },
        closeDialog() {

            // Close the confirmation dialog

            this.showConfirmation = false;

            // Reload the page to the top

            // window.scrollTo(0, 0);

            window.location.reload();

        },
    },
};
</script>

<style scoped>
.custom-modal {

    position: fixed;

    top: 0;

    left: 0;

    width: 100%;

    height: 100%;

    background: rgba(0, 0, 0, 0.7);

    display: flex;

    justify-content: center;

    align-items: center;

    z-index: 999;

}

.modal-content {

    background: white;

    padding: 20px;

    border-radius: 5px;

    text-align: center;

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

    color: black;

}

.modal-content button {

    background-color: grey;

    color: white;

    border: none;

    padding: 10px 20px;

    cursor: pointer;

    margin-top: 20px;

    border-radius: 5px;

}
</style>
