<template>
  <v-theme-provider dark>
    <base-section
      id="contact-us"
      class="primary"
      space="36"
    >
      <v-row
        align="center"
        class="ma-0"
        justify="center"
      >
        <base-title
          space="0"
          title="GET OUR NEWSLETTER"
        />

        <form @submit.prevent="submitForm">
          <v-responsive
            class="px-4 mx-6"
            max-width="600"
            width="100%"
          >
            <base-text-field
              hide-details
              label="Your Email Address"
              v-model="email"
            >
              <template #append-outer>
                <v-btn
                  class="ml-n1"
                  height="40"
                  outlined
                  style="margin-top: -7px;"
                  type="submit"
                >
                  Subscribe
                </v-btn>
              </template>
            </base-text-field>
          </v-responsive>
        </form>
      </v-row>
    </base-section>
  </v-theme-provider>
</template>

<script>
export default {
  name: 'SectionNewsletterAlt',
  data() {
    return {
      email: '', // Vue data property to store the email address
      googleFormActionURL: 'https://docs.google.com/forms/d/e/1FAIpQLSe6hAzJ9WoSeslw6Ylwd5LPGh1LsU30C_UsQofrJut6ddMI1g/formResponse', // Replace with your Google Form Action URL
    };
  },
  methods: {
    async submitForm() {
      try {
        const formData = new FormData();
        formData.append('entry.2042709075', this.email); // Replace with the form field name for email

        await fetch(this.googleFormActionURL, {
          method: 'POST',
          body: formData,
          mode: 'no-cors',
        });

        // Optionally, you can add code here to show a success message or handle notifications.
        console.log('Email submitted successfully');

        // Clear the email input field after submission
        this.email = '';
      } catch (error) {
        console.error('Error submitting email:', error);
        // Handle errors here
      }
    },
  },
};
</script>