<template>
    <div>
        <div>
            <div v-for="(item,i) in content.topText.paragraphs" :key="i">
                <p>{{ item }}</p>
                <p v-if="i === 2">
                    <ul>
                        <li v-for="(liPoint,index) in content.topText.liPoints" :key="index">{{ liPoint }}</li>
                    </ul>
                </p>
            </div>
        </div>
        <div>
            <div><strong>{{ content.specificBenefits.heading }}</strong></div>
            <p>
                <ul>
                    <li v-for="(item,i) in content.specificBenefits.liPoints" :key="i">{{ item }}</li>
                </ul>
            </p>
        </div>
        <div>
            <div><strong>{{ content.impactOnStartupIndustry.heading }}</strong></div>
            <p v-for="(item,i) in content.impactOnStartupIndustry.paragraphs" :key="i">{{ item }}</p>
        </div>
        <div>
            <div><strong>{{ content.penaltiesOnComplianceFailure.heading }}</strong></div>
            <p v-for="(item,i) in content.penaltiesOnComplianceFailure.paragraphs" :key="i">{{ item }}</p>
            <p><ul>
                <li v-for="(item,i) in content.penaltiesOnComplianceFailure.liPoints" :key="i">{{ item }}</li>
            </ul></p>
        </div>
        <div>
            <div><strong>{{ content.WhatCompaniesCanDo.heading }}</strong></div>
            <p v-for="(item,i) in content.WhatCompaniesCanDo.paragraphs" :key="i">{{ item }}</p>
            <p><ul>
                <li v-for="(item,i) in content.WhatCompaniesCanDo.liPoints" :key="i">{{ item }}</li>
            </ul></p>
        </div>
        <div>{{ content.bottomText }}</div>
    </div>
</template>

<script>
export default {
  name: "article-4",
  title: "Understanding Digital Personal Data Protection Bill 2023",
  url: "understanding-digital-personal-data-protection-bill-2023",
  data() {
    return {
      content: {
        topText: {
          paragraphs: [
            "One of the most pivotal actions in the context of Digital India was carried out by the Indian Parliament when they recently ratified the Digital Personal Data Protection Bill of 2023. The enactment of the Digital Data Privacy Protection Bill constitutes a momentous stride in fortifying data security in India. By endowing individuals with substantial rights pertaining to their personal data, the Bill erects a robust regulatory scaffold designed to ensure the safeguarding of these privileges.",
            "In its essence, the Bill offers a distinct characterization of personal data, encapsulating any particulars capable of singling out an individual, ranging from particulars like their name, residence, contact details such as phone number and email address, to financial data. ",
            "The Bill outlines a range of fundamental principles that dictate the management of personal data, encompassing the following guidelines:",
            "As a result, the Bill plays a pivotal role in safeguarding individual privacy during the digital era. With an increasing portion of our lives being conducted online, it becomes imperative to establish robust measures that shield our personal information. The Bill achieves this by overseeing the methods through which organizations amass, employ, and distribute our personal data.",
            "Furthermore, the Bill serves to cultivate trust and confidence within the digital economy. Entities that gather and utilize personal data must adopt transparency in their practices and ensure responsible data utilization. The Bill contributes to this objective by necessitating that entities obtain consent from individuals prior to data collection and by enforcing data security measures.",
            "The Bill also fosters an equitable business landscape. Irrespective of their size or sector, all enterprises will be held to the same standards when it comes to collecting and utilizing personal data. This provision cultivates an environment of fairness and competitiveness among businesses.",
          ],
          liPoints: [
            "The collection of personal data must have a legitimate purpose, and subsequent processing must adhere to the limitations set by that predefined objective.",
            "Prior consent from individuals is obligatory before any processing of their personal data occurs.",
            "Entities responsible for personal data (referred to as data fiduciaries) have a responsibility to implement measures ensuring the security of said data.",
            "Individuals retain the right to access, correct, and erase their personal data.",
          ],
        },
        specificBenefits: {
          heading:
            "Specific benefits that the Data Privacy Protection Bill will bring to Indian citizens:",
          liPoints: [
            "Enhanced personal data governance: The Bill bestows individuals with the authority to access, rectify, erase, and transfer their personal data. This empowerment translates into individuals wielding heightened control over how businesses utilize their personal information.",
            "Augmented transparency: Businesses will be compelled to enhance their transparency regarding the methods through which they gather, utilize, and distribute personal data. This alteration will simplify the comprehension of data usage for individuals, enabling them to make knowledgeable decisions about data sharing.",
            "Reinforced data security: Businesses will be obligated to implement measures that fortify the security of personal data. This effort will serve as a deterrent against data breaches and the inappropriate use of personal data.",
            "Robust law enforcement: The Bill institutes a Data Protection Authority (DPA) tasked with supervising the execution of the legislation. Empowered to probe grievances, levy penalties, and issue directives to businesses, the DPA ensures the effective enforcement of the law.",
          ],
        },
        impactOnStartupIndustry: {
          heading: "Impact on startup industry:",
          paragraphs: [
            'Startups that are already under regulations, such as lending fintechs or payment aggregators, must follow strict rules for handling and storing data as set by the Reserve Bank of India. However, some experts believe that there might be an extra set of rules for them to follow now. This is because fintech and crypto startups could be grouped as "Important Data Custodians."',
            'An "Important Data Custodian" would need to choose a data protection officer and an independent data auditor to check if they are following the rules of the Act.',
            'Bigger tech companies and social media giants might also fall into the "important" category. These companies have a huge amount of customer data. The management of financial services might be left to the regulator.',
            "More rights for customers: One of the most important parts of the Act is that if a customer's data is breached, the customer must be told about it. This is crucial because companies used to keep these breaches a secret. For example, a payment company called Juspay was attacked by cybercriminals in August 2020. But news about the attack only came out much later, in 2021. The company had told the businesses it works with, but they didn't inform the regulator.",
          ],
        },
        penaltiesOnComplianceFailure: {
          heading: "Penalties on compliance failure",
          paragraphs: [
            "Companies that fail to comply with the Bill may face a number of penalties, including:",
          ],
          liPoints: [
            "Directions from the Data Protection Authority to stop processing personal data or to take corrective measures.",
            "Breaching to inform the data board or the data principal the instance of a data theft can attract a penalty up to Rs 200 crore.",
          ],
        },
        WhatCompaniesCanDo: {
          heading:
            "What Companies can do to Comply with the Data Privacy Protection Bill, 2023",
          paragraphs: [
            "While this law will impact all organizations in the country that deal with the personal data of Indian residents, it's anticipated that tech companies and startups serving consumers will feel the most significant effects. Since the penalties for not following the rules are substantial, it's vital for businesses to take actions to adhere to the Bill. Here are some specific measures that companies can adopt to adhere to the Bill:",
          ],
          liPoints: [
            "Create a privacy policy: The initial step is crafting a privacy policy that outlines how the company collects, uses, and shares personal data. This policy should be straightforward and clear, and it should be accessible to individuals whose data is gathered.",
            "Get consent: Prior to collecting personal data, businesses must get permission from the individuals. This consent should be given willingly, with clear understanding, and without any ambiguity.",
            "Legitimate data use: Companies can only employ personal data for the reasons it was initially collected. If a company wishes to use the data for a new purpose, they must acquire fresh consent from the individual.",
            "Ensure data security: Businesses need to take measures to keep personal data secure. This includes using suitable technical and organizational methods to prevent unauthorized access, disclosure, or loss of data.",
            "Respect individual rights: Companies must respect the rights granted to individuals by the Bill, including the right to access, correct, erase, and transfer their personal data.",
            "Designate a data protection officer: Companies handling large volumes of personal data or engaging in specific sensitive data processes need to assign a data protection officer. This officer is responsible for overseeing compliance with the Bill.",
          ],
        },
        bottomText:
          "By following these steps, businesses can contribute to ensuring their adherence to the Digital Data Privacy Protection Bill of 2023.",
      },
    };
  },
};
</script>

<style scoped>
* {
  text-align: justify;
}
</style>