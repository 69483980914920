<template>
  <v-theme-provider dark>
    <section id="hero-alt">
      <base-img
        :min-height="minHeight"
        :max-height="300"
        :gradient="gradient"
        :src="src"
        color="#45516b"
        flat
        max-width="100%"
        tile
      >
        <v-row
          v-if="title"
          align="center"
          class="ma-0 fill-height text-center"
          justify="center"
        >
          <v-col cols="12">
            <base-heading :title="title.split('-')[0]" space="2" weight="500" :size="size" :mobileSize="mobileSize"/>
            <p v-if="title.split('-')[1] !== undefined">{{ title.split('-')[1] }}</p>
            <!-- <base-divider
              color="primary"
              dense
            />

            <v-breadcrumbs
              :items="items"
              class="justify-center pa-0"
              divider=">"
            /> -->
          </v-col>
        </v-row>
      </base-img>
    </section>
  </v-theme-provider>
</template>

<script>
// Components
import { HexToRGBA, RGBAtoCSS } from "vuetify/lib/util/colorUtils";

export default {
  name: "SectionHeroAlt",

  // metaInfo() {
  //   return {
  //     changed: (meta) => (this.title = meta.titleChunk.toUpperCase()),
  //   };
  // },
  props:{
    title:String,
  },
  provide: {
    heading: { align: "center" },
  },

  // data: () => ({
  //   title: "",
  // }),

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "40vh" : "12vh";
      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
    gradient() {
      const color = `${this.$vuetify.theme.themes.light.secondary}CC`;
      const overlay = RGBAtoCSS(HexToRGBA(color));

      return `to top, ${overlay}, ${overlay}`;
    },
    src() {
      return this.$route.meta.src;
    },
    items() {
      return [{ text: "HOME", to: "/" }, { text: this.title }];
    },
    size(){
      return this.title.split('-')[0].length > 60 ? "text-h5" : "text-h3";
    },
    mobileSize(){
      return this.title.split('-')[0].length > 60 ? "text-h6" : "text-h4";
    }
  },
};
</script>

<style lang="sass">
#hero-alt
  .v-breadcrumbs__item
    color: #FFFFFF
</style>
