<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require('@/assets/logo.png')"
        contain
        max-width="128"
        min-height="78"
        width="100%"
      />

      <base-title
        size="text-body-1"
        space="4"
        title="HEDGE VISION SYSTEMS PVT. LTD."
        weight="regular"
      />

      <base-body>
        Hedge Vision Systems was founded in 2020 with the focus on accelerating
        the digital transformation, cloud adaptability and building data driven
        apps with data security at its core. Accelerate your digital
        transformation, data security and governance with us.
      </base-body>

      <base-btn class="mb-12" color="white" outlined> More Info </base-btn>
    </div>
  </v-theme-provider>
</template>

<script>
export default {
  name: "BaseInfo",

  props: {
    title: String,
    dark: Boolean,
  },
};
</script>
