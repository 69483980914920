<template>
    <div>
        <div>
            <div><br /><strong>{{ content.introduction.heading }}</strong><br /></div>
            <p v-for="(item,i) in content.introduction.paragraphs" :key="i">{{ item }}</p>
        </div>
        <div>
            <div><strong>{{ content.understanding.heading }}</strong></div>
            <p v-for="(item,i) in content.understanding.paragraphs" :key="i">{{ item }}</p>
            <p><ol>
                <li v-for="(item,i) in content.understanding.liPoints" :key="i"><b>{{content.understanding.bulletheadings[i]}}</b> {{ item }}
                <ul v-if="i===3">
                 <li v-for="(item,j) in content.understanding.liMidPoints" :key="j"> {{ item }}</li> 
                </ul>
                </li>
                
            </ol></p>
        </div>
        <div>
            <div><br /><strong>{{ content.keyChallenges.heading }}</strong><br /></div>
            <p>
                <ul>
                    <li v-for="(item,i) in content.keyChallenges.liPoints" :key="i"><b>{{content.keyChallenges.bulletheadings[i]}}</b> {{ item }}</li>
                </ul>
            </p>
        </div>
        <div>
            <div><strong>{{ content.advancedAnalytics.heading }}</strong></div>
            <p><ul>
                <li v-for="(item,i) in content.advancedAnalytics.liPoints1" :key="i">{{ item }}</li>
            </ul></p>
            <p v-for="(item,i) in content.advancedAnalytics.paragraphs1" :key="i">{{ item }}</p>
            <p><b>{{content.advancedAnalytics.etlHeading}}</b> {{content.advancedAnalytics.etl}}</p>
            <p>{{content.advancedAnalytics.paragraphs2}}</p>
             <p>
                <ul>
                    <li v-for="(item,i) in content.advancedAnalytics.liPoints2" :key="i"><b>{{content.advancedAnalytics.bulletheadings2[i]}}</b> {{ item }}</li>
                </ul>
            </p>
            <p>{{content.advancedAnalytics.paragraphs3}}</p> 
        </div>
        <div>
            <div><br /><strong>{{ content.cloudELT.heading }}</strong><br /></div>
            <p v-for="(item,i) in content.cloudELT.paragraphs" :key="i">{{ item }}</p>
            <v-img max-height="40vh" :src="require(`@/assets/${content.cloudELT.image}`)" contain></v-img>
        </div>
        <div>
            <div><br /><strong>{{ content.whyWarehouse.heading }}</strong><br /></div>
            <p v-for="(item,i) in content.whyWarehouse.paragraphs1" :key="i">{{ item }}</p>
            <p>
                <ol>
                    <li v-for="(item,i) in content.whyWarehouse.liPoints" :key="i">{{ item }}
                      <ul v-if="i===0">
                 <li v-for="(item,j) in content.understanding.liMidPoints" :key="j"> {{ item }}</li> 
                </ul>
                    </li>
                </ol>
            </p>
            <p v-for="(item,i) in content.whyWarehouse.paragraphs2" :key="i">{{ item }}</p>
        </div>
        <div>
            <div><br /><strong>{{ content.hvs.heading }}</strong><br /></div>
            <p v-for="(item,i) in content.hvs.paragraphs1" :key="i">{{ item }}</p>
            <p>
                <ol>
                    <li v-for="(item,i) in content.hvs.liPoints1" :key="i">{{ item }}</li>
                </ol>
            </p>
            <p v-for="(item,i) in content.hvs.paragraphs2" :key="i">{{ item }}</p>
            <p>
                <ol>
                    <li v-for="(item,i) in content.hvs.liPoints2" :key="i">{{ item }}</li>
                </ol>
            </p>
            <p v-for="(item,i) in content.hvs.paragraphs3" :key="i">{{ item }}</p>
        </div>
    </div>
</template>

<script>
export default {
  name: "article-6",
  title:
    "Data Warehousing in Partnership and Portfolio Accounting - Key Challenges, Solutions and Best Practices",
  url: "data-warehousing-and-portfolio-accounting",
  data() {
    return {
      content: {
        introduction: {
          heading: "What is a Data Warehouse",
          paragraphs: [
            "Data is growing exponentially in volume, variety, and velocity. IDC expects that by 2025, the global datasphere will grow to 175 zettabytes, and nearly 30% of the data will be generated in real-time.  Data is becoming key source of competitive advantage and value creation for asset management firms that can leverage it effectively. Data is being used to enhance various aspects of the asset management value chain, such as distribution, investment, and operations. There is lots of data that is being generated from various sources, such as market data, alternative data, client data, internal data, web, mobile, social media, sensors, and IoT devices. Data is also being stored and processed in different formats, such as structured, semi-structured, and unstructured. In 2023, data is becoming a strategic asset that requires careful management and governance and is becoming a valuable asset for businesses that want to gain insights, improve decision making, and enhance customer experience.",
            "One of the ways that asset managers can manage and leverage their data more efficiently and effectively is by using a data warehouse. A data warehouse is a system that collects data from various operational databases and external sources, transforms it into a common format, and loads it into a dedicated server. A data warehouse enables fast and efficient query processing, reporting, and analytics on large volumes of data. A data warehouse also provides a single source of truth for all the data in the organization.",
          ],
        },
        understanding: {
          heading:
            "Understanding the process of Data Warehouse in Portfolio Management",
          paragraphs: [
            "Data warehouse can help in portfolio management in several ways. Portfolio management is the process of selecting and managing a mix of assets that meet the investment objectives and risk preferences of an investor or a firm. Data warehouse can provide a centralized, consistent, and reliable source of data for portfolio analysis, reporting, and optimization. Here is the overview of process.",
          ],
          bulletheadings: [
            "Identify Data Requirements: ",
            "Data Source Integration: ",
            "Data Modelling and Design: ",
            "Data Storage and Architecture: ",
            "Data Transformation and Cleansing: ",
            "Data Loading: ",
            "Data Access and Analysis: ",
          ],
          liPoints: [
            "The first step is to identify the data requirements of the asset management firm. This involves understanding the types of data needed for investment analysis, risk management, compliance, reporting, and other aspects of portfolio management. The data requirements may include market data, financial statements, economic indicators, company-specific data, alternative data, and more.",
            "Once the data requirements are established, the next step is to integrate data from various sources. This can include internal systems like trading platforms, portfolio management software, accounting systems, as well as external sources like market data providers, news feeds, and alternative data providers. Data integration may involve data extraction, transformation, and loading (ETL) processes to standardize and cleanse the data.",
            "A crucial step is to design the data model for the data warehouse. This involves defining the structure of the data warehouse, including data tables, relationships between tables, and data hierarchies. The data model should be optimized for efficient querying and reporting.",
            "Choose the appropriate data storage technology and architecture for the data warehouse. This can include traditional relational databases, columnar databases, cloud-based storage solutions, or a combination of these depending on the volume and nature of the data. Data warehouses can be one-, two-, or three-tier structures. Perhaps the most common, however, is the three-tier architectural structure, which looks as follows:",
            "Data transformation and cleansing processes are applied to ensure data consistency, accuracy, and reliability. Data quality is essential for making sound investment decisions.",
            "The transformed and cleansed data is loaded into the data warehouse. Depending on the frequency of data updates, this process may be scheduled to occur at regular intervals, such as daily or hourly.",
            "Once the data is loaded into the data warehouse, asset managers can access and analyse it using business intelligence tools, analytics software, or custom-built applications. They can create reports, dashboards, and perform ad-hoc queries to gain insights into their portfolios, market trends, risk exposure, and performance.",
          ],
          liMidPoints: [
            "Bottom tier, also called the data tier, in which the data is supplied to the warehouse. ",
            "Middle tier, also called the application tier, in which an OLAP server processes the data. ",
            "Top tier, also called the presentation tier, which is designed for end-users with particular tools and application programming interfaces (APIs) used for data extraction and analysis.",
          ],
        },
        keyChallenges: {
          heading: "Key challenges of partnership and portfolio accounting",
          paragraphs: [
            "The global data warehousing market size was valued at $21.18 billion in 2019, and is projected to reach $51.18 billion by 2028, growing at a CAGR of 10.7% from 2020 to 2028 (Source: Allied Market Research). It is important for us to understand the Data warehouses and its use cases as a powerful tool to help solve some of the key challenges of partnership and portfolio accounting by providing a stable, centralized repository for large amounts of historical data that can be used to improve business processes and decision-making with actionable insights. Some of these challenges are:",
          ],
          bulletheadings: [
            "Data integration: ",
            "Data quality: ",
            "Data analysis: ",
            "Data visualization: ",
            "Data security and compliance: ",
          ],
          liPoints: [
            "Partnership and portfolio accounting often involves data from multiple sources, such as custodians, brokers, fund administrators, market data providers, and internal systems. Data warehouses can help integrate and consolidate this data into a single, consistent, and reliable source of truth for reporting and analysis.",
            "Portfolio accounting requires accurate and timely data to ensure compliance, performance measurement, and risk management. Data warehouses can help improve data quality by applying validation rules, cleansing processes, and reconciliation procedures to ensure the data is complete, correct, and consistent.",
            "Many complex calculations and aggregations across different dimensions, such as asset classes, currencies, accounting bases, and reporting regimens are involved. Data warehouses can help facilitate data analysis by providing a flexible and scalable data model that supports various analytical functions, such as slicing, dicing, drilling down, and rolling up.",
            "Partnership and portfolio accounting requires clear and effective communication of the results and insights derived from the data. Data warehouses can help enhance data visualization by providing tools and platforms that enable the creation of interactive dashboards, charts, graphs, and reports that can be customized to meet the needs and preferences of different stakeholders.",
            "Portfolio managers need to ensure that the data they use is secure and compliant with the relevant laws and regulations. Data warehouse helps to protect the data from unauthorized access, modification, or deletion, and provide various security features, such as encryption, authentication, authorization, and auditing. It also helps to comply with the regulatory requirements, such as data privacy, data retention, and data governance.",
          ],
        },
        advancedAnalytics: {
          heading:
            "Some of the ways that asset managers are applying advanced analytics with help of data warehouse to improve their performance are:",
          liPoints1: [
            "Optimizing distribution and service models by building vast data reservoirs of multidimensional client characteristics to design distribution and service models that better enable them to cover the right clients, through the right channels, at the right time.",
            "Improving productivity through precision targeting by using machine learning to identify the most promising prospects and opportunities based on behavioural and contextual signals.",
            "Enhancing performance management by using data-driven dashboards and feedback loops to monitor and improve sales effectiveness and efficiency.",
          ],
          paragraphs1: [
            "A data warehouse is not a replacement for operational databases, but rather a complement to them. Operational databases are designed to handle transactional processing and real-time updates, while data warehouses are designed to handle analytical processing and batch loading. Operational databases are optimized for OLTP (Online Transaction Processing) workloads, while data warehouses are optimized for OLAP (Online Analytical Processing) workloads. Data warehouse has the highest adoption of data solutions, used by 54% of organizations (Flexera 2021)",
          ],
          etlHeading: "ETL process and its importance: ",
          etl: "ETL stands for Extract, Transform, and Load, and it is a data integration process that combines data from multiple data sources into a single, consistent data store that is loaded into a data warehouse or other target system1. ETL is important because it enables data analysis, reporting, and machine learning on large volumes of data from various sources and formats.",
          paragraphs2: "The ETL process consists of three main steps:",
          bulletheadings2: ["Extract: ", "Transform: ", "Load: "],
          liPoints2: [
            "This is the process of collecting and importing data from different sources, such as databases, files, APIs, cloud storage, etc., into a staging area. The extraction can be done using different methods, such as batch loading, streaming, or change data capture. The extraction process ensures that the data is available and accessible for further processing.",
            "This is the process of cleaning, validating, enriching, and standardizing the data in the staging area. The transformation can be done using different techniques, such as data quality rules, data mapping, data integration, etc. The transformation process ensures that the data is consistent, accurate, and ready for analysis.",
            "This is the process of transferring the transformed data from the staging area to the target system, such as a data warehouse or a data lake. The loading can be done using different methods, such as full load, incremental load. The loading process ensures that the data is stored and organized in a way that supports fast and efficient querying and analysis.",
          ],
          paragraphs3:
            "The ETL process is an iterative process that is repeated as new data is added to the warehouse. The process is important because it ensures that the data in the data warehouse is accurate, complete, and up to date. It also helps to ensure that the data is in the format required for data mining and reporting3. ETL provides the foundation for data analytics and machine learning workstreams. Through a series of business rules, ETL cleanses and organizes data in a way which addresses specific business intelligence needs, like monthly reporting, but it can also tackle more advanced analytics, which can improve back-end processes or end user experiences.",
        },
        cloudELT: {
          heading: "The Cloud-Enabled ELT Model ",
          paragraphs: [
            "ELT stands for Extract, Load, and Transform. It means that your data is collected from various sources, load it to a secure and scalable datastore in the cloud, and then transform it according to your business rules and requirements. This way, you can take advantage of the cloud’s high-performance computing power, which will enable you to access and process your data faster and more efficiently. Moreover, ELT model works with a data lake as the destination for your data. A data lake is different from a traditional data warehouse because it does not require your data to have a predefined structure or schema. This gives you more flexibility and agility in handling your data, as well as allowing you to use advanced tools such as AI to analyse and act on your data while it is being transformed in the data lake. With our ELT model, you can get the most value out of your data and make better decisions for your asset management.",
          ],
          image: "Datawarehouse.png",
        },
        whyWarehouse: {
          heading: "Why you need a Data Warehouse",
          paragraphs1: [
            "You may wonder why you need a Data Warehouse to perform Business Analytics, when you can directly use your transactional data and BI tools. However, there are many benefits of using a Data Warehouse that you may not be aware of. Here are some of them:",
          ],
          liPoints: [
            "A Data Warehouse is a single source of truth for all your data within your company. It helps you avoid problems such as:",
            "A Data Warehouse allows you to run analysis on large volumes of data quickly and easily.",
            "A Data Warehouse protects your data from changes in the structure of your transactional data. It ensures that your business reports are always accurate and reliable, regardless of the changes in your operational or transactional databases.",
            "A Data Warehouse is cost-effective and scalable, especially if you use a cloud-based Data Warehouse. You only pay for what you use, and you can adjust the size of your data as it grows. You can read more about the comparison of these two cloud-based Data Warehouses in this article.",
            "A Data Warehouse enables you to share your data with others in your company for analysis, while keeping sensitive information (such as personal or partner data) hidden or encrypted.",
            "A Data Warehouse improves the performance of your queries, as it stores your data in a denormalized form, unlike your transactional databases that store your data in a normalized form. This makes your queries faster and more efficient.",
            "A Data Warehouse improves data quality and consistency by applying standard rules and validations to the data, reduces data redundancy and duplication by storing only relevant and aggregated data, enhances data security and privacy by implementing access control and audit mechanisms, supports historical analysis and trend discovery by storing data over time.",
            "Unlocking data-driven capabilities by allowing asset managers to access and analyse data without relying much on data specialists.",
            "Data Warehouse increases operational efficiency by using automation, robotics, and natural language processing to streamline and optimize various middle- and back-office processes. Enhancing risk management by using advanced analytics to monitor and mitigate various types of risks, such as market risk, credit risk, liquidity risk, operational risk, and regulatory risk.",
          ],
          paragraphs2: [
            "These are some of the reasons why you need a Data Warehouse for Business Analytics. A Data Warehouse can help you gain valuable insights from your data and make better decisions for your asset management.",
            "To summarize, the current scenario of data is that it is diverse, complex, and massive. The need of data warehouse is to simplify, unify, and leverage data for business intelligence and analytics. A data warehouse is a powerful tool that can help organizations gain competitive advantage and achieve their goals.",
            "The need of data warehouse is to store, integrate, and analyse data from multiple sources in a centralized and consistent manner. A data warehouse is a system that collects data from various operational databases and external sources, transforms it into a common format, and loads it into a dedicated server. A data warehouse enables fast and efficient query processing, reporting, and analytics on large volumes of data. A data warehouse also provides a single source of truth for all the data in the organization.",
          ],
          liMidPoints: [
            "Data quality issues",
            "Unstable data in reports",
            "Data inconsistency",
            "Low query performance",
          ],
        },
        hvs: {
          heading: "Hedge Vision Systems as your Data Warehouse provider",
          paragraphs1: [
            "We as your IT service provider company specializes in data warehousing and analytics. We are here to help you transform your data into insights and value.",
            "As an alternate asset manager, you need to have reliable, timely, and consistent data to support your decision making. You need to access and analyse data from various sources, such as market data, portfolio data, client data, and regulatory data. You also need to comply with the ever-changing rules and regulations in the industry. However, managing and integrating all these data can be challenging and costly. You may face issues such as data silos, data quality problems, data security risks, and data latency. These issues can affect your performance, efficiency, and competitiveness in the market.",
            "That's where Hedge Vision Systems came with a data warehouse service. Our data warehouse service can help you:",
          ],
          liPoints1: [
            "Build a single source of truth for all your data needs. We can design and implement a data warehouse that suits your specific requirements and objectives. We can also migrate your existing data from different systems and formats into the data warehouse.",
            "Enable faster and more accurate reporting and analysis. We can provide you with various tools and technologies (ELT, ETL, cloud enabled, SQL) to access and query your data warehouse. We can also create customized dashboards and reports that show you the key metrics and insights you need.",
            "Facilitate data governance and security. We can ensure that your data warehouse follows the best practices and standards for data quality, consistency, and integrity. We can also protect your data from unauthorized access and breaches with our advanced encryption and authentication methods.",
          ],
          paragraphs2: [
            "With our data warehouse service, you can benefit from:",
          ],
          liPoints2: [
            "Improved decision making. You can leverage the power of data and analytics to make better and faster decisions that enhance your investment performance, optimize your distribution strategy, and increase your operational productivity.",
            "Reduced costs and risks. You can save time and money by eliminating the need for multiple data systems and processes. You can also reduce the risks of errors, fines, and reputational damage by complying with the industry regulations.",
            "Increased agility and innovation. You can adapt to the changing market conditions and customer expectations by having a flexible and scalable data warehouse. You can also explore new opportunities and insights by using advanced analytics techniques such as machine learning and artificial intelligence.",
          ],
          paragraphs3: [
            "Hedge Vision Systems data warehouse solution offers a seamless, customizable, and smart way to manage front, middle and back-office operations. It is designed to be user-friendly and deliver fast and efficient analysis.",
          ],
        },
      },
    };
  },
};
</script>

<style scoped>
* {
  text-align: justify;
}
</style>