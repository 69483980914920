<template>
  <div :class="classes" class="pt-2">
    <base-avatar
      v-if="icon"
      :color="color"
      :dark="dark"
      :icon="icon"
      :outlined="outlined"
      :size="size"
      class="mb-3 align-self-start"
    />
    <!-- <base-avatar
      v-if="image && customText === 'TokenX Features'"
      :color="color"
      :dark="dark"
      :outlined="outlined"
      :size="size"
      :image="image"
      class="mb-3 align-self-start"
    /> -->
    <v-img
      contain
      v-if="image && customText === 'Features'"
      :src="require(`@/assets/${image}.png`)"
      :alt="image"
      flat
      tile
      :height="$vuetify.breakpoint.mdAndDown ? '82' : 'auto'"
      :width="$vuetify.breakpoint.mdAndDown ? '82' : 'auto'"
      max-height="100px"
      max-width="100px"
    />
    <div :class="horizontal && title && 'ml-6'">
      <base-title :title="title" class="text-uppercase" space="3" />

      <base-body
        v-if="text || $slots.default"
        :space="horizontal ? 0 : undefined"
        :text="text"
        max-width="700"
        :customText="customText"
      >
        <slot />
      </base-body>
      <base-subtitle v-if="link"
        ><router-link style="text-decoration: none; color: blue" :to="link"
          >Read more...</router-link
        ></base-subtitle
      >
      <v-img
        v-if="image && customText === 'altProducts'"
        :src="require(`@/assets/${image}.png`)"
        :alt="image"
        flat
        tile
        contain
        class="mx-10"
        :class="invert ? 'invert' : none"
        height="100"
        width="500"
      />
      <v-img
        v-if="image && customText === 'altConsulting'"
        :src="require(`@/assets/${image}.png`)"
        :alt="image"
        flat
        tile
        contain
        class="mx-10 invert"
        height="100"
        width="500"
      />
    </div>
  </div>
</template>

<script>
// Mixins
import Heading from "@/mixins/heading";

export default {
  name: "BaseAvatarCard",

  mixins: [Heading],

  props: {
    align: {
      type: String,
      default: "left",
    },
    color: String,
    dark: Boolean,
    horizontal: Boolean,
    icon: String,
    outlined: {
      type: Boolean,
      default: true,
    },
    space: {
      type: [Number, String],
      default: 8,
    },
    size: {
      type: [Number, String],
      default: 72,
    },
    text: String,
    link: String,
    title: String,
    image: String,
    customText: String,
    invert: String,
  },

  computed: {
    classes() {
      const classes = [`mb-${this.space}`];

      if (this.horizontal) {
        classes.push("d-flex");

        if (!this.$slots.default && !this.text) {
          classes.push("align-center");
        }
      }

      return classes;
    },
  },
};
</script>
<style scoped>
.invert {
  filter: invert(99%) contrast(102%);
}
</style>
